// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto:300,400,700&display=swap');
//@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// element-ui
//@import 'element-variables';

// custom classes
@import "classes";
