
//  Navigation -
nav{
	padding: 20px 0;
	margin-bottom: 40px;
	.logo{
		height: 60px;
	}
	.nav-link{
		font-weight: 600;
	}

}
.navbar{
	padding:0;
	height:64px;
	.navbar-nav{
		.nav-item{
			padding:12px 15px;
			border-bottom: 2px solid transparent;
		}
		.nav-item:hover {
			border-bottom: 2px solid $blue;
		}
	}
}

@media (max-width: 767.98px) {
	.navbar-toggler{
		height:64px;
		margin-bottom: 2px;
	}
	.navbar-nav {
		background: #fff;
		z-index: 100;
		position: relative;
	}
}
//  - Navigation

/** Layout */
.footer {
	margin-top: auto;
	width: 100%;
	line-height: 60px;
	background-color: #f5f5f5;
}

.justify-content-center{
    margin:0;
}

// ** -- layout **/
// Defaults -
html, body {
	height: 100vh;
}
body{
	display: flex;
	flex-direction: column;
	background: $body-bg;
	font-size: $font-size-base;
}
main{
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
p{
	color: #222;
}
a {
	color: $secondary;
}

h1{
	font-size: 32px;
	font-weight: bold;
	padding: 10px 20px;
}
label{
	font-size:80%;
	color: #999;
	margin-bottom: 0;
}

table label {
	font-size: 90%;
	color: #666666;
}
table label.label-left {
	font-size: 90%;
	color: #999999;
	width: 120px;
}
// Defaults -


// Controls -
.btn{
	//font-size: 120%;
	padding: 0.5rem 1.3rem;
	font-weight: 600;
}
.btn-link{
	padding: 0;
	font-size: 120%;
}
.btn-default{
	border:1px solid $border-color;
}
.btn-circle {
	padding: 6px;
	border-radius: 50%;
	text-align: center;
	margin-bottom: 10px;
	i{
		float:left;
	}
}
.badge{
	padding: 0.35em 0.4em;
}
.badge-notification {
    position: relative;
    margin-right: 10px;
}
.badge-notification[data-badge]::after {
    content: attr(data-badge);
    position: absolute;
    top: -5px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 50%;
    background: var(--secondary);
    color: #fff;
}
span.badge-notification {
    margin-right: 24px;
}
span.badge-notification[data-badge]::after {
    top: -11px;
    right: -24px;
}
/* Experiments */
.form-signin {
	width: 100%;
	max-width: 430px;
	padding: 15px;
	margin: auto;
}

:root {
	--input-padding-x: .75rem;
	--input-padding-y: .75rem;
}

.form-label-group {
	position: relative;
	margin-bottom: 1rem;
	text-align: left;
	> input, > select, > label {
		padding: var(--input-padding-y) var(--input-padding-x);
	}

	> label {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		margin-bottom: 0; /* Override default `<label>` margin */
		line-height: 1.3;
		color: #495057;
		font-size: 1.2rem;
		border: 1px solid transparent;
		border-radius: .25rem;
		transition: all .1s ease-in-out;
	}

	input::placeholder,select::placeholder {
		color: transparent;
	}

	input:not(:placeholder-shown),select:not(:placeholder-shown)  {
		padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
		padding-bottom: calc(var(--input-padding-y) / 3);
	}

	input:not(:placeholder-shown) ~ label,select:not(:placeholder-shown) ~ label {
		padding-top: calc(var(--input-padding-y) / 3);
		padding-bottom: calc(var(--input-padding-y) / 3);
		font-size: 12px;
		color: #777;
	}
}

/* - Experiments */

.form-control{
	font-size:1.2rem;
	height: calc(1.6em + 0.75rem + 8px);
}
.form-check-input{
	width:20px;
	height:20px;
	float:left;
	position:inherit;
	margin: 3px 10px 0 0;
}
.label{
	font-size: $font-size-base;
	color:#777;
}
// - Controls

// Lists -
.list-group-item{
	border:1px solid transparent;
	margin-bottom: 2px;
	padding: 0;
	a{
		padding: 0.75rem 1.25rem;
		width: 100%;
	}
}
.dropdown-menu{
	font-size: 90%;
}


/** Notification **/
// SCSS:

.notifications {
    width:400px !important;
    top:15px !important;
    right:15px !important;

    .notification {
        margin: 0 0 10px 0;
        padding: 10px 20px;
        color: $font-color;
        background: $border-color;
        border-left: 5px solid $border-color;

        .notification-title {
            font-size: 90%;
        }

        .notification-content {
            // Style for content
        }

        &.warning {
            background: $warning;
            border-left-color: darkorange;
        }

        &.success {
            background: $success;
            border-left-color: darkgreen;
            color: $default;
        }

        &.danger {
            background: $danger;
            border-left-color: darkred;
            color: $default;
        }
    }
}


/* Colors */
.bg-ube{
	background-color: rgba(214, 162, 232, 0.26) !important;
}
.bg-oasis{
	background-color: rgba(154, 236, 219, 0.26) !important;
}
.bg-tealDeer{
    background-color: #86FAAF !important;
}
.bg-ufoGreen{
    background-color: #00D765 !important;
}
.vue-form-wizard .wizard-tab-content {width:100%;}
