// Body
$body-bg: #fafafa;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 1.2rem;
$line-height-base: 1.4;
$font-color: #212529;

// Colors
$blue: #2292A4;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #EF006F;
$red: #E15554;
$orange: #f6993f;
$yellow: #FFC857;
$gold: #cfa034;
$green: #3BB273;
$teal: #BDBF09;
$cyan: #68D8D6;

$brand-primary: #3BB273;
$primary: #3BB273;
$default: #f5f5f5;

$secondary: $blue;

$border-color: #dce0e0;

// Bootstrap
@import "~bootstrap/scss/bootstrap";
